import $ from 'jquery';
import * as signalR from '@microsoft/signalr';

export default class RamphastosSignalRComService {

    // because: https://stackoverflow.com/a/47164318/3737186
    static get $$ngIsClass() { return true; }

    constructor($rootScope,
                $timeout,
                $interval,
                $http,
                $q,
                $injector,
                $location,
                $templateCache,
                ramphastosApplicationService,
                ramphastosEncodingService,
                ramphastosCyclicJsonService,
                ramphastosResourceUpdateService,
                ramphastosRemoteSessionStorageService,
                ramphastosHTMLService,
                ramphastosStringSplitService,
        ramphastosLocaleService) {
        var $ = window.jQuery; //trick to share jQuery instance. Otherwise this webpack entry would have it's own instance.
        //var s = signalR;
        var ramphastosSignalRHub = null;
        var connection = null;
        var signalRInitialized;
        var tryingToReconnect;
        var connectionConfig = {};
        var reconnectTimer = undefined;
        var keepAliveTimer = undefined;
        var sessionAccessToken = '';
        var counter = 0;
        var isConnected = false;
        var waitingPromises = {};

        //We want not to show to the user that we lost connection while we are 
        //attempting a reconnect with a query string access token
        var awaitingReconnectWithToken = false;
        var handleConnectionFailure = undefined;
        var alertOnClose = false;
        var windowId;

        var tabId = sessionStorage.tabID && sessionStorage.closedLastTab !== '2' ? sessionStorage.tabID : sessionStorage.tabID = Math.random();
        tabId = tabId.toString().replace(/\./g, '');
        sessionStorage.closedLastTab = '2';
        $(window).on('unload beforeunload', function () {
            sessionStorage.closedLastTab = '1';
        });

        var getRamSignalRConfig = function () {
            try {
                var ramTransport = $('meta[name=ram-transport]').attr("content");
                if (ramTransport === null || ramTransport === undefined) {
                    return {};
                }
                ramTransport = parseInt(ramTransport);
                if (ramTransport === 1) {
                    return { transport: 'webSockets' };
                } else if (ramTransport === 2) {
                    return { transport: 'longPolling' };
                } else if (ramTransport === 3) {
                    return { transport: 'foreverFrame' };
                } else if (ramTransport === 4) {
                    return { transport: 'serverSentEvents' };
                } else {
                    console.error("Unkown transport protocol. SignalR defaults will be applied.");
                    return {};
                }
            } catch (error) {
                console.error("Error while trying to read ram-transport from meta data");
                return {};
            }
        };

        var loadModuleTemplates = function (locale, callback) {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', 'ramphastos/ModuleTemplates?locale=' + locale, true);
            xhr.setRequestHeader("access_token", sessionAccessToken);
            xhr.setRequestHeader("windowId", windowId);
            xhr.setRequestHeader("locale", locale);

            xhr.responseType = 'json';
            xhr.onload = function () {
                try {
                    var json = xhr.response;
                    var moduleTransport = angular.fromJson(json);
                    $.each(moduleTransport.Templates,
                        function (key, value) {
                            $templateCache.put(key, value);
                        });
                } catch (e) {
                    console.error("Error while trying to load templates.", e.stack);
                }

                if (callback !== undefined) {
                    callback();
                }
            };
            xhr.onerror = function (e) {
                console.error("Error while attempting to read module templates", e);
                if (callback !== undefined) {
                    callback();
                }
            };

            xhr.abort = function () {
                console.error("Request for templates canceled");
                if (callback !== undefined) {
                    callback();
                }
            };
            xhr.send();
        };

        var getLocationUrl = function () {
            var url = $location.protocol() + "://" + $location.host();
            if ($location.port() !== 80) {
                url += ":" + $location.port();
            }
            return url;
        };


        var setQueryStringAccessTokenContinued = function (accessToken, routingUrl, locale) {
            // When this code piece is commented in then after the reload most of the time the localStorage is empty causing  
            // an application start without access-token. Reason is yet unknown. TODO: find the reason and enable this again
            /*
            if (locale !== null) {
                ramphastosLocaleService.updateLocale(locale);  //this could trigger a reload
            }
            */

            //remove access token from query string if present:
            $location.search('access_token', null);

            if (connection != null) {
                awaitingReconnectWithToken = true;
                isConnected = false;
                console.log("Stopping connection");

                // we can stop the connection either from client or server and see an ugly error message in the console. Unfortunately...
                //connection.invoke("AbortServerConnection");
                connection.stop(); //Seems to cause this: https://stackoverflow.com/questions/53944572/signalr-core-error-websocket-closed-with-status-code-1006

                connection = undefined; //prevent other from using this connection
                setTimeout(function () {
                    console.log("Restarting connection after change of access-token");
                    startConnection(true, routingUrl);
                    awaitingReconnectWithToken = false;
                    }, 100);
            }
            return;
        };


        var setQueryStringAccessToken = function (accessToken, routingUrl, locale) {
            sessionAccessToken = accessToken;
            ramphastosRemoteSessionStorageService.storeToken(sessionAccessToken);

            if (routingUrl !== null) {
                var promise = ramphastosApplicationService.updateRoutingUrl(routingUrl); //this could trigger a reload
                promise.then(function () {
                    ramphastosRemoteSessionStorageService.storeToken(sessionAccessToken);
                    setQueryStringAccessTokenContinued(accessToken, routingUrl, locale);
                });
            } else {
                setQueryStringAccessTokenContinued(accessToken, null, locale);
            }
        };



        var startConnection = function (isReconnectAfterTokenChange, routingUrl) {
            // Make sure the parameter is false in case it is not given
            if (isReconnectAfterTokenChange !== true) {
                isReconnectAfterTokenChange = false;
            }

            let url = "/ramphastosHub";
            let location = getLocationUrl();
            if (location !== undefined && location !== null && location !== '') {
                url += "?location=" + location;
            }

            console.log("building new connection");
            let builder = new signalR.HubConnectionBuilder()
                .withUrl(url, { accessTokenFactory: () => sessionAccessToken });
                

            if (ramphastosApplicationService.debugInfoEnabled()) {
                builder = builder.configureLogging(2); //we consider warning here, because we don't want to debug signalr itself.
            } else {
                builder = builder.configureLogging(4);
            }


            connection = builder.build();
            
            connection.serverTimeoutInMilliseconds = 2*60*1000; // 2 min

            // This method is used to stop corrupt session or to stop the session in case there
            connection.on("StopConnection", function (reason, userMessage) {
                connectionConfig.ContinuouslyReconnect = false; //prevent automatic reconnect in case we actively stop the connection.
                alertOnClose = false;
                connection.stop();
                console.error("Server asked to stop connection. Reason: " + reason);
                if (userMessage !== undefined && userMessage !== null) {
                    $rootScope.$emit('connectionStatusChanged', { status: 'disconnected', message: userMessage });
                }
            });
            connection.on("Run", function (newWindowId, windowsModelJson, angularJsModulesJson, config) {
                //make sure we take over the windowId from the server
                windowId = newWindowId;
                ramphastosRemoteSessionStorageService.storeWindowId(windowId);
                if (ramphastosApplicationService.debugInfoEnabled()) {
                    console.log("window-id given from server: " + windowId);
                }
                sessionStorage.setItem("ramTabWindowPair", tabId + "," + windowId);

                connectionConfig = config;
                var windowModel = ramphastosCyclicJsonService.fromCJSON(windowsModelJson);
                var angularJsModules = ramphastosCyclicJsonService.fromCJSON(angularJsModulesJson);


                loadModuleTemplates(windowModel.Locale, function () {
                    ramphastosApplicationService.run(windowModel, angularJsModules, config.ContentDeliveryUrl);

                    // special for web case: handle icon of website
                    if (windowModel.Icon !== undefined && windowModel.Icon !== null) {
                        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
                        link.type = 'image/x-icon';
                        link.rel = 'shortcut icon';
                        link.href = ramphastosApplicationService.buildResourceLink(windowModel.Icon);
                        document.getElementsByTagName('head')[0].appendChild(link);
                    }
                    // This is also specially handled in the signalR implementation:
                    // notify server if this tab has the focus, we need to do this here as we can only do this once the
                    // signalR connections lives.
                    if (document.hasFocus()) {
                        connection.invoke("UpdateHasFocus", windowId, true);
                    }

                    //set up a keep-alive-timer
                    if (keepAliveTimer !== undefined) {
                        $interval.cancel(keepAliveTimer);
                    }
                    connection.invoke("KeepAlive");
                    keepAliveTimer = $interval(function () {
                            if (isConnected) {
                                try {
                                    connection.invoke("KeepAlive");
                                } catch (error) {
                                    this.handleConnectionFailure(error);
                                }     
                            }
                        },
                    connectionConfig.connectionKeepAliveIntervalSeconds * 1000);
                });

                return;
            });
            connection.on("UpdateViewModel", function (viewModelJson) {
                var viewModel = ramphastosCyclicJsonService.fromCJSON(viewModelJson);
                ramphastosApplicationService.updateViewModel(viewModel);
            });
            connection.on("UpdateSharedObject", function (id, sharedObjectJson) {
                var sharedObject = ramphastosCyclicJsonService.fromCJSON(sharedObjectJson);
                ramphastosApplicationService.updateSharedObject(id, sharedObject);
            });

            connection.on("UpdateViewModelProperties", function (ramphastosPath, viewModelObjectHash, deltaJson) {
                ramphastosApplicationService.updateViewModelProperties(ramphastosPath, viewModelObjectHash, deltaJson);
            });

            connection.on("Call", function (ramphastosPath, fname, parametersJson) {
                const parameters = ramphastosCyclicJsonService.retrocycle(parametersJson);
                const pascalCaseParameters = [];

                parameters.forEach(obj => {
                    pascalCaseParameters.push(capitalizeKeys(obj));
                });

                ramphastosApplicationService.call(ramphastosPath, fname, pascalCaseParameters);
            });
            connection.on("Error", function (msg) {
                ramphastosApplicationService.error(msg);
            });
            connection.on("SetQueryStringAccessToken", function (accessToken, routingUrl, locale) {
                setQueryStringAccessToken(accessToken, routingUrl, locale);
            });
            connection.on("UpdateRoutingUrl", function (routingUrl) {
                ramphastosApplicationService.updateRoutingUrl(routingUrl);
            });
            connection.on("UpdateLocale", function (locale) {
                ramphastosLocaleService.updateLocale(locale);
            });
            connection.on("ResourceChanged", function (files) {
                ramphastosResourceUpdateService.resourceChanged(files);
            });
            connection.on("GetDomSnapshot", function (id) {
                ramphastosHTMLService.getHTMLWithInlinedResources().then(function (html) {
                    returnHtmlDomSnapShot(id, html);
                });
            });
            connection.on("Log",
                function (message) {
                    console.log(message);
                });

            connection.on("Error",
                function (message) {
                    console.error(message);
                });

            connection.on("OpenView", function (windowId) {
                var otherViewUrl = $location.absUrl();
                if (otherViewUrl.indexOf('?') === -1) {
                    otherViewUrl += "?__windowId=" + windowId;
                } else {
                    otherViewUrl += "__windowId=" + windowId;
                }
                // TODO: trigger a popup where the user has to confirm
                window.open(otherViewUrl, '_blank');
            });

            connection.on("ResolveCommandV2", function (messageNumber, value) {
                if (waitingPromises[messageNumber] !== undefined) {
                    waitingPromises[messageNumber][0](value);
                    delete waitingPromises[messageNumber];
                }
            });

            connection.on("RejectCommandV2", function (messageNumber) {
                if (waitingPromises[messageNumber] !== undefined) {
                    waitingPromises[messageNumber][1](value);
                    delete waitingPromises[messageNumber];
                }
            });

            connection.onclose(async (error) => {
                console.log("Connection closed");
                if (error !== undefined && error !== null && alertOnClose) {
                    $rootScope.$emit('connectionStatusChanged', { status: 'disconnected', message: "Lost connection to the server" });
                    alertOnClose = false;
                }
            });

            connection.start()
                .then(function () {
                    isConnected = true;
                    if (reconnectTimer !== undefined) {
                        $timeout.cancel(reconnectTimer); //cancel reconnect timer    
                    }
                    //connection successful
                    signalRInitialized = true;
                    //reset the counter here in case of reconnect
                    counter = 0;
                    //in this case we have to trigger the application startup in here

                    //set the view-id. Different browser tabs/windows have different view-ids
                    var searchObj = $location.search();
                    windowId = null;

                    var ramRequestResyncOnly = sessionStorage.getItem('ramRequestResyncOnly');
                    if (ramRequestResyncOnly) {
                        sessionStorage.removeItem('ramRequestResyncOnly');
                    }

                    if (!isReconnectAfterTokenChange && !ramRequestResyncOnly) {
                        if (searchObj['__windowId'] !== undefined) {
                            // Step 1: Look if URL contains an explicit view-id
                            windowId = searchObj['__windowId'];
                            $location.search('__windowId', null);
                            ramphastosRemoteSessionStorageService.storeWindowId(windowId);
                            if (ramphastosApplicationService.debugInfoEnabled()) {
                                console.log("Window-id from GET parameter '__windowId': " + windowId);
                            }
                        } else if (searchObj['__new'] !== undefined) {
                            // Step 2: Look if URL contains the wish to create a new view (a little deprecated)
                            windowId = ramphastosRemoteSessionStorageService.createGuid();
                            $location.search('__new', null);
                            ramphastosRemoteSessionStorageService.storeWindowId(windowId);
                            if (ramphastosApplicationService.debugInfoEnabled()) {
                                console.log("New window-id as indicated by the GET parameter '__new': " + windowId);
                            }
                        } else {
                            windowId = null;
                        }

                        if (windowId === null) {
                            //Step 3: Look if the windows-id is paired with
                            var ramTabWindowPair = sessionStorage.getItem('ramTabWindowPair');
                            if (ramTabWindowPair !== null) {
                                ramTabWindowPair = ramTabWindowPair.split(',');
                                if (ramTabWindowPair[0] === tabId && ramTabWindowPair[1] !== null) {
                                    windowId = ramTabWindowPair[1];
                                    if (ramphastosApplicationService.debugInfoEnabled()) {
                                        console.log("window-id from tab link: " + windowId);
                                    }
                                }
                            }
                        }
                        if (windowId === null) {
                            // Step 4: Look if we have a view-id in the meta-tag
                            windowId = ramphastosRemoteSessionStorageService.tryRetrieveWindowIdFromMetaData();
                            if (windowId !== null) {
                                ramphastosRemoteSessionStorageService.storeWindowId(windowId);
                            }
                            if (ramphastosApplicationService.debugInfoEnabled()) {
                                console.log("window-id from meta-tag: " + windowId);
                            }
                        }
                    }
                    if (windowId === null) {

                        windowId = ramphastosRemoteSessionStorageService.retriveWindowId();
                        if (windowId !== null) {
                            if (ramphastosApplicationService.debugInfoEnabled()) {
                                console.log("window-id from session-storage: " + windowId);
                            }
                        }
                    }
                    if (ramphastosApplicationService.debugInfoEnabled()) {
                        console.log("current window-id: " + windowId);
                    }
                    sessionStorage.setItem("ramTabWindowPair", tabId + "," + windowId);
                    if (isReconnectAfterTokenChange || ramRequestResyncOnly) {
                        //don't change the routing from here, we are resuming not really starting!
                        if (routingUrl !== undefined) {
                            connection.invoke("StartApplication", windowId, routingUrl, null);
                        } else {
                            connection.invoke("StartApplication", windowId, null, null);
                        }
                    } else {
                        connection.invoke("StartApplication", windowId, $location.path(), $location.search());
                    }
                    alertOnClose = true;
                    //ensure status update
                    $rootScope.$emit('connectionStatusChanged', { status: 'connected' });
                })
                .catch(function (error) {
                    handleConnectionFailure(error);
                });
        };

        handleConnectionFailure = function (error) {
            isConnected = false;
            alertOnClose = false;
            var message = "";
            console.warn("Failed to start connection to application server.", error);
            try {
                if (sessionAccessToken) {
                    //we assume we have an access token that is not valid anymore
                    console.log("Trying to restart the connection without access token (anonymous session). " +
                        "SignalR connection failed with status code: ");
                    connection.stop(); //stop connection first -> this will set the status to disconnected
                    //update status only after connection was stopped (otherwise stopping will overwrite it again)

                    var unknownError = false;
                    // If we get an 401 the session has expired, so we will silently go back to the login.
                    if (error.statusCode !== 401) {
                        unknownError = true;
                        message = 'Connection failed. Status code was ' +
                            error.statusCode +
                            '. ' +
                            'Maybe the session is not valid any more. A new session will start in a few seconds...';
                    }
                    $rootScope.$emit('connectionStatusChanged',
                        {
                            status: 'error',
                            message: message
                        });
                    sessionAccessToken = undefined;
                    ramphastosRemoteSessionStorageService.clear();
                    if (connectionConfig.ContinuouslyReconnect) {
                        reconnectTimer = $timeout(function () {
                                awaitingReconnectWithToken = false;
                                //so we can fall back to the continuous reconnect mode or show that we are really disconnected.
                                reconnectTimer = undefined;
                                startConnection();
                            },
                            connectionConfig.ContinuouslyReconnectInterval);
                    }
                    else {
                        $timeout.cancel(reconnectTimer);
                        reconnectTimer = undefined;
                    }
                } else {
                    message = "Connection to server failed";
                    $rootScope.$emit('connectionStatusChanged',
                        {
                            status: 'error',
                            message: message
                        });
                }
            } catch (e) {
                console.error("Error while trying to handle connection failure.", e.stack);
            }

        };





        


        this.initialize = function (scope, location) {
            //ramphastosSignalRHub = $.connection.ramphastosHub; //store signalR connection

            //TODO: move to following calls to a seperate function

            /**********************************************************************************************************/
            /* server to client API
            /**********************************************************************************************************/

            
            




            ///**********************************************************************************************************/
            ///* signalr event handling
            ///**********************************************************************************************************/

            ////TODO: move to following calls to a separate function
            ////handle connection lifetime events 
            ////(http://www.asp.net/signalr/overview/guide-to-the-api/handling-connection-lifetime-events)

            //$.connection.hub.reconnecting(function () {
            //    isConnected = false;
            //    tryingToReconnect = true;
            //    sessionStorage.setItem('ramRequestResyncOnly', true); // next time we reconnect we attempt to only resync
            //    $rootScope.$emit('connectionStatusChanged', { status: 'reconnecting' });
            //});
            //$.connection.hub.reconnected(function () {
            //    tryingToReconnect = false;
            //    $rootScope.$emit('connectionStatusChanged', { status: 'connected' });
            //    startConnection();
            //});
            //$.connection.hub.disconnected(function () {
            //    $interval.cancel(keepAliveTimer);
            //    isConnected = false;
            //    if (awaitingReconnectWithToken) {
            //        //schedule a manual reconnect attempt after 5 seconds
            //        reconnectTimer = $timeout(function () {
            //            //so we can fall back to the continuous reconnect mode or show that we are really disconnected.
            //            reconnectTimer = undefined;
            //            startConnection(awaitingReconnectWithToken);
            //            awaitingReconnectWithToken = false;
            //        },
            //            5000);
            //        return;
            //    }
            //    //try to reconnect continuously if configured to do so
            //    try {
            //        if (connectionConfig.ContinuouslyReconnect) {
            //            console.log("trying continuous reconnect");
            //            reconnectTimer = $timeout(function () {
            //                reconnectTimer = undefined;
            //                startConnection();
            //            },
            //                connectionConfig.ContinuouslyReconnectInterval);
            //            $rootScope.$emit('connectionStatusChanged', { status: 'continuouslyReconnect' });
            //        } else {
            //            $rootScope.$emit('connectionStatusChanged', { status: 'disconnected' });
            //        }
            //    } catch (e) {
            //        $rootScope.$emit('connectionStatusChanged', { status: 'disconnected' });
            //        console.error("Error while trying to apply connection configuration for continous reconnect",
            //            e.stack);
            //    }
            //});
            //$rootScope.$emit('connectionStatusChanged', { status: 'disconnected' });


            /**********************************************************************************************************/
            /* location & access_token
            /**********************************************************************************************************/

            //set accessToken if it is available from the storage //TODO: move to separate function
            var locationObj = $location.search();
            if (locationObj.access_token !== undefined && location.access_token !== null) {
                sessionAccessToken = locationObj.access_token;
                ramphastosRemoteSessionStorageService.storeToken(sessionAccessToken);
            } else {
                var token = ramphastosRemoteSessionStorageService.retriveToken();
                if (token !== null) {
                    sessionAccessToken = token;
                }
            }

            startConnection();
        };

        /**************************************************************************************************************/
        /* client to server API
        /**************************************************************************************************************/

        this.execute = function (commandId, parameters) {
            connection.invoke("ExecuteCommand", windowId, commandId, parameters, counter++);
        };

        this.executeV2 = function (targetType, targetId, methodName, parameterTypes, parameters) {
            var promiseResolve, promiseReject;
            var promise = new Promise(function(resolve, reject) {
                try {
                    promiseResolve = resolve;
                    promiseReject = reject;
                    var messageCounter = counter;
                    waitingPromises[messageCounter] = [promiseResolve, promiseReject];
                    connection.invoke("ExecuteCommandV2",
                        windowId,
                        {
                            targetType: targetType,
                            targetId: targetId,
                            methodName: methodName,
                            parameterTypes: parameterTypes,
                            parameters: parameters
                        },
                        messageCounter
                    ).then(function(val) {
                        if (val?.RamphastosType === "RamphastosCommandV2CompletionTicket") {
                            //we have to wait
                        } else {
                            resolve(val);
                            delete waitingPromises[messageCounter];
                        }
                    });
                } catch (err) {
                    console.error("Failed to execute commandV2", err);
                    reject();
                }
            });

            counter++;
            return promise;
        };


        this.applyClientDiff = function (viewModelId, delta) {
            // messages should be smaller than 32kB, with a max of 16kB message size (+ additional overhead), we're on the safe side
            var chunkSize = 16 * 1024;
            var deltaJson = angular.toJson(delta);

            if (deltaJson.length > chunkSize) {
                var chunks = [];
                for (var it = 0, charsLength = deltaJson.length; it < charsLength; it += chunkSize) {
                    chunks.push(deltaJson.substring(it, it + chunkSize));
                }

                for (var i = 0; i < chunks.length; i++) {
                    connection.invoke("ApplyPartialClientDiff", windowId, viewModelId, chunks[i], counter, i, chunks.length);
                }
            } else {
                connection.invoke("ApplyClientDiff", windowId, viewModelId, deltaJson, counter);
            }
            counter++;
        };

        //TODO: write unit-test where $http service is mocked, test file names, token, etc.
        this.loadFile = function (ramphastosPath, fileLoaderFunction, files) {
            //Make a HTTP POST request with multipart form data
            var formdata = new FormData();
            for (var i = 0; i < files.length; i++) {
                formdata.append("file_" + i, files[i], files[i].name);
            }
            $http.post('ramphastos/FileUpload/',
                formdata,
                {
                    headers: {
                        'Content-Type': undefined,
                        'access_token': sessionAccessToken,
                        'windowId': windowId,
                        'ramphastosPath': ramphastosPath,
                        'fileLoaderFunction': fileLoaderFunction
                    },
                    transformRequest: angular.identity
                })
                .then(
                    function () {

                    },
                    function (response) {
                        console.error(response);
                    }
                );
        };

        this.downloadFile = function (ramphastosPath, fileProviderFunction) {
            //Make a HTTP POST request with multipart form data

            var xhr = new XMLHttpRequest();
            xhr.open('GET', 'ramphastos/FileDownload/', true);
            xhr.setRequestHeader("ramphastosPath", ramphastosPath);
            xhr.setRequestHeader("fileProviderFunction", fileProviderFunction);
            xhr.setRequestHeader("access_token", sessionAccessToken);
            xhr.setRequestHeader("windowId", windowId);

            //The next 3 headers are needed for IE - so it doesn't caches the response
            xhr.setRequestHeader("Pragma", 'no-cache');
            xhr.setRequestHeader("Cache-Control", 'no-cache');
            xhr.setRequestHeader("Expires", -1);

            xhr.responseType = 'blob';
            xhr.onload = function () {
                if (this.status == 200) {
                    var blob = xhr.response;
                    var responseHeader = xhr.getResponseHeader("Content-Disposition");
                    if (responseHeader === null) {
                        console.error("Cannot retrieve the filename for download. The header doesn't exist in the response.");
                        return;
                    }
                    var fileName = responseHeader?.match(/\sfilename="?([^"]+)"?(?:;)/)[1];
                    saveBlob(blob, fileName);
                }
            }
            xhr.send();
        };

        // we need to pascal case all nested objects because Colibri expects pascal case js properties
        function capitalizeKeys(obj) {
            const isObject = o => Object.prototype.toString.apply(o) === '[object Object]'
            const isArray = o => Object.prototype.toString.apply(o) === '[object Array]'
            let isJson = false;
            try {
                const parsed = JSON.parse(obj);
                if (parsed && typeof parsed === "object") {
                    obj = parsed;
                    isJson = true;
                }
            } catch {
            }

            try {
                const parsed = JSON.parse(obj);
                if (parsed && typeof parsed === "object") {
                    obj = parsed;
                }
            } catch {
            }
            
            let transformedObj = isArray(obj) ? [] : {};

            if (typeof obj === 'object') {
                for (let key in obj) {
                    if (!isGuid(key)) {
                        const transformedKey = key[0].toUpperCase() + key.slice(1);

                        if (isObject(obj[key]) || isArray(obj[key])) {
                            transformedObj[transformedKey] = capitalizeKeys(obj[key])
                        } else {
                            transformedObj[transformedKey] = obj[key]
                        }
                    } else {
                        transformedObj[key] = obj[key]
                    }

                }
            } else {
                if (obj[0] && !isGuid(obj)) {
                    const result = obj[0].toUpperCase() + obj.slice(1);
                    return isJson ? JSON.stringify(result) : result;
                } else {
                    return isJson ? JSON.stringify(obj) : obj
                }
            }

            return isJson ? JSON.stringify(transformedObj) : transformedObj
        }

        function isGuid(value) {
            var regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
            var match = regex.exec(value);
            return match != null;
        }

        function saveBlob(blob, fileName) {
            if (window.navigator && (window.navigator.msSaveOrOpenBlob || window.navigator.msSaveBlob)) {
                //Internet Explorer
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    window.navigator.msSaveBlob(blob, fileName);
                }
            } else {
                var a = document.createElement("a");
                var blobUrl = window.URL.createObjectURL(blob);
                a.href = blobUrl;
                a.download = fileName;

                document.body.appendChild(a); //necessary for firefox
                a.click();
                a.remove(); //necessary for firefox
                window.URL.revokeObjectURL(blobUrl);
            }
        }

        this.updateRoutingUrlFromClient = function (routingUrl) {
            connection.invoke("UpdateRoutingUrlFromClient", windowId, routingUrl);
        };

        this.requestResync = function (ramphastosPath) {
            connection.invoke("RequestResync", windowId, ramphastosPath);
        };

        //TODO: write unit-tests
        this.getScreenSharingLink = function () {
            var token = ramphastosRemoteSessionStorageService.retriveToken();
            var windowId = ramphastosRemoteSessionStorageService.retriveWindowId();
            var url = $location.absUrl();
            if (url.indexOf('?') > -1) {
                url += "&";
            } else {
                url += "?";
            }
            url += "access_token=" + token + "&__windowId=" + windowId;
            return url;
        };

        var returnHtmlDomSnapShot = function (id, html) {
            var chuncks = ramphastosStringSplitService.split(html, 50000, true);
            var numChuncks = chuncks.length;
            $.each(chuncks, function (index, value) {
                connection.invoke("ReturnHtmlDomSnapShot", windowId, id, index, numChuncks, value).catch(function (err) {
                    return console.error('returnHtmlDomSnapShot error: ', err.toString());
                });
            });
        };

        var updateHasFocusTimeout = null;
        this.updateHasFocus = function (hasFocus) {
            if (isConnected) {
                try {
                    // We delay call to HasFocus server side in order to not make the server overflow with too many calls
                    if (updateHasFocusTimeout !== null) {
                        //console.log("Canceling timeout for updateHasFocus");
                        $timeout.cancel(updateHasFocusTimeout);
                        updateHasFocusTimeout = null;
                    }
                    updateHasFocusTimeout = $timeout(function () {
                        connection.invoke("UpdateHasFocus", windowId, hasFocus);
                    },
                        200,
                        false
                    );
                } catch (e) {
                    console.error("Error while trying to update hasFocus", e.stack);
                }
            }
        };
    }
}
